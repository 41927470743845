body .css-13cymwt-control {
    min-height: 30px !important;
    border-radius: 0.5rem  !important;
    border-style: solid;
    border-color: hsl(var(--default-300) / var(--tw-border-opacity)) !important;
}
.css-13cymwt-control:focus{
    /* border-color:red !important; */
}
.css-13cymwt-control .css-hlgwow  , 
.css-13cymwt-control .css-1wy0on6
{
    
    margin-top: -2px;
    font-size: 13px;
}
.cus-sty-01{
    padding: 2px 2px !important;
    height: 22px !important;

}
/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  .no-data-icon-sty{
    width: 400px;
  height: 290px;
  margin: auto;
  }
 